// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---content-notes-gh-repo-clone-ssh-md": () => import("./../content/notes/gh-repo-clone-ssh.md" /* webpackChunkName: "component---content-notes-gh-repo-clone-ssh-md" */),
  "component---content-notes-release-workflow-with-changesets-md": () => import("./../content/notes/release-workflow-with-changesets.md" /* webpackChunkName: "component---content-notes-release-workflow-with-changesets-md" */),
  "component---content-notes-set-up-changesets-md": () => import("./../content/notes/set-up-changesets.md" /* webpackChunkName: "component---content-notes-set-up-changesets-md" */),
  "component---content-notes-setting-up-a-new-mac-md": () => import("./../content/notes/setting-up-a-new-mac.md" /* webpackChunkName: "component---content-notes-setting-up-a-new-mac-md" */),
  "component---content-notes-test-md": () => import("./../content/notes/test.md" /* webpackChunkName: "component---content-notes-test-md" */),
  "component---content-notes-uses-md": () => import("./../content/notes/uses.md" /* webpackChunkName: "component---content-notes-uses-md" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

